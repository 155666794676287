import Kbar, { KbarListItem } from "../Kbar";
import { HeaderTransition, OffsetTransition } from "../Motion";
import ScrollWrapper from "../Motion/scroll";
import Tabs from "../Tabs";
import { getCalApi } from "@calcom/embed-react";
import Icon from "@twilight-toolkit/ui/src/Icon/icon";
import { useTheme } from "next-themes";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "~/hooks";
import useAnalytics from "~/hooks/analytics";
import { selectGeneral } from "~/store/general/selectors";
import {
	updateKbarToSearch,
	activateKbar,
	updateKbarSearchQuery,
} from "~/store/kbar/actions"


interface HeaderSearchBarComponentProps {
	activateKbar: () => void
}

const HeaderSearchBarComponent = ({
	activateKbar,
}: HeaderSearchBarComponentProps) => {
	const [userAgent, setUserAgent] = useState(null)

	useEffect(() => {
		const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
		setUserAgent(isMac)
	}, []);

	return (
		<div className="effect-pressing hidden lg:flex lg:w-[65%] xl:w-[620px]">
			<div
				aria-label={`${userAgent ? 'Command' : 'Control'} + K to open the command palette`}
				className="absolute left-3 top-[6px] z-10 cursor-not-allowed rounded-md border bg-gray-50 px-1.5 py-0.5 text-xs text-gray-400 dark:border-gray-600 dark:bg-transparent">
				{userAgent ? (<span className="flex flex-row justify-center items-center [&>svg]:w-4.5 [&>svg]:h-4.5 [&>svg]:scale-90 [&>svg]:-ml-1 [&>svg]:-mr-0.5"><Icon name="command" />{"+K"}</span>) : (<span className="flex flex-row justify-center items-center">{"CTRL+K"}</span>)}
			</div>
			<input
				type="text"
				className={`w-full rounded-md border border-gray-200 bg-white bg-opacity-90 px-3 py-2 ${userAgent ? "pl-[58px]" : "pl-[77px]"} text-sm text-gray-500 outline-none transition-shadow hover:bg-neutral-50 dark:border-gray-700 dark:bg-gray-800 dark:bg-opacity-50 dark:shadow-sm dark:hover:border-gray-700 dark:hover:bg-gray-800 dark:hover:bg-opacity-100`}
				placeholder="Type your command or search..."
				onFocus={activateKbar}
				data-oa="click-activateKbar"
				data-cy="cmdkbutton"
			/>
		</div>
	)
}

const HeaderTitleComponent = () => {
	const { headerTitle } = useSelector(selectGeneral)

	if (!headerTitle) return null

	return (
		<div className="mx-auto hidden items-center justify-center space-x-3 overflow-hidden lg:flex">
			<h3 className="overflow-hidden text-ellipsis whitespace-nowrap font-medium select-none">
				{headerTitle}
			</h3>
		</div>
	)
}

interface HeaderComponentProps {
	headerRef: MutableRefObject<HTMLDivElement>
}

const HeaderComponent = ({ headerRef }: HeaderComponentProps) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const { trackEvent } = useAnalytics()
	const { theme, resolvedTheme, setTheme } = useTheme()
	const [ loadedTheme, setLoadedTheme ] = useState('system')
	const calRef = useRef(null)

	const toggleTheme = (theme: string) => {
		if ( !document.startViewTransition ) {
			setTheme(theme)
			setLoadedTheme(theme)
		} else {
			document.startViewTransition(() => {
				setTheme(theme)
				setLoadedTheme(theme)
			})
		}
	}

	useEffect(() => {
		setLoadedTheme(theme)
	}, [theme, resolvedTheme, loadedTheme])

	let lightSwitch, darkSwitch;

	lightSwitch = {
		label: "Night Mode",
		id: "darktheme",
		shortcut: ["d"],
		description: "Command",
		icon: "moon",
		tabClassName: "block dark:hidden",
		action: () => toggleTheme("dark"),
	};

	darkSwitch = {
		label: "Day Mode",
		id: "lighttheme",
		shortcut: ["l"],
		description: "Command",
		icon: "sun",
		tabClassName: "hidden dark:block",
		action: () => toggleTheme("light"),
	}

	const themeList = [
		lightSwitch, darkSwitch,
		{
			label: "System Setting",
			id: "systemtheme",
			shortcut: ["y"],
			description: "Command",
			icon: "monitor",
			action: () => toggleTheme("system"),
		},
	];

	const titleRef = useRef<HTMLDivElement>(null)

	const nonHomePage = router.pathname.split("/").length > 2

	let [namespace, setNamespace] = useState(0);
	let [currentTheme, setCurrentTheme] = useState('dark');

	useEffect(() => {
		setNamespace(namespace + 1);
	}, []);

	useEffect(() => {
		const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		const systemTheme = mediaQuery.matches ? "dark" : mediaQuery.matches;
		setCurrentTheme((theme === "system" ? systemTheme : theme).toString());

		(async function () {
			const cal = await getCalApi({ namespace: `meeting-${namespace}-alt` });
			cal("ui", {
				theme: currentTheme === "dark" ? "dark" : "light",
				hideEventTypeDetails: false,
				layout: "month_view",
			});
		})();
	}, [theme]);

	const leftTabItems = [
		{
			label: "Avatar",
			hoverable: false,
			component: (
				<div className="group mx-auto flex cursor-pointer items-center justify-center space-x-3 px-5">
					<div className="flex h-[18px] w-[18px] flex-shrink-0 items-center rounded-full border border-gray-300 dark:border-gray-500">
						{/* <a
							href="https://opensea.io/assets/ethereum/0x13bd2ac3779cbbcb2ac874c33f1145dd71ce41ee/3690"
							target="_blank"
							rel="noreferrer"
							className="flex"
						> */}
						<Image
							className="rounded-full"
							src="/static/profile/me.jpg"
							alt="David"
							height={18}
							width={18}
							loading="lazy"
						/>
						{/* </a> */}
					</div>
					<div className="text-3 font-medium text-black">
						<Link href="/" passHref>
							<h3 className="text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-300">
								David M. Coleman
							</h3>
						</Link>
					</div>
				</div>
			),
		},
		{
			label: "Places",
			className: "hidden lg:block",
			color: "text-pink-500",
			bgColor: "bg-pink-100",
			bgDark: "dark:bg-pink-900",
			icon: "rainbow",
			link: {
				internal: "/places",
			},
		},
	]

	const rightTabItems = [
		router.pathname.startsWith("/blog") ?
			{
				label: "Home",
				className: "hidden lg:block",
				icon: "home",
				link: {
					internal: "/",
				},
			} :
			{
				label: "Blog",
				className: "hidden lg:block",
				icon: "blog",
				link: {
					internal: "/blog",
				},
			},
		// {
		// 	label: "Pages",
		// 	className: "hidden lg:block",
		// 	icon: "pages",
		// 	link: {
		// 		internal: "/pages",
		// 	},
		// },
		{
			label: "About",
			icon: "me",
			link: {
				internal: "/about",
			},
		},
	]

	const kbarItems: KbarListItem[] = [
		{
			label: "Navigation",
			id: "navigation-divider",
			hoverable: false,
		},
		{
			label: "Go Back",
			id: "back",
			icon: "left",
			shortcut: ["b"],
			action: () => router.back(),
			description: "Command",
		},
		{
			label: "Home",
			id: "home",
			icon: "home",
			shortcut: ["h"],
			description: "Command",
			link: {
				internal: "/",
			},
		},
		{
			label: "Appearance",
			id: "appearance-divider",
			hoverable: false,
		},
		{
			label: "Themes",
			id: "themes",
			icon: loadedTheme === "light" ? "sun" : "moon",
			description: "Choices",
			shortcut: ["t"],
			singleton: false,
			sublist: {
				key: "themes",
				list: themeList,
				placeholder: "Set theme to...",
			},
		},
		{
			label: "Search",
			id: "search-divider",
			hoverable: false,
		},
		{
			label: "Search Blog Posts",
			id: "search",
			icon: "search",
			shortcut: ["s"],
			description: "Command",
			singleton: false,
			action: () => {
				dispatch(updateKbarToSearch())
				trackEvent("searchBlogPosts", "kbar")
			},
			onInputChange: (query: string) => {
				dispatch(updateKbarSearchQuery(query))
			},
		},
		{
			label: "Actions",
			id: "actions-divider",
			hoverable: false,
		},
		{
			label: "Subscribe to RSS Feed",
			id: "rss",
			description: "Link",
			icon: "rss",
			color: "text-yellow-500",
			bgColor: "bg-yellow-100",
			bgDark: "dark:bg-yellow-900",
			link: {
				external: "/feed",
			},
		},
		{
			label: "Message me on Discord",
			id: "discord",
			description: "Link",
			icon: "chatRounded",
			color: "text-purple-400",
			bgColor: "bg-purple-100",
			bgDark: "dark:bg-purple-900",
			link: {
				external: "https://discord.com/users/198169754215645185",
			},
		},
		{
			label: "Sponsor me",
			id: "sponsor",
			description: "Page",
			icon: "love",
			color: "text-pink-500",
			bgColor: "bg-pink-100",
			bgDark: "dark:bg-pink-900",
			link: {
				internal: "/places#sponsor",
			},
		},
		{
			label: "Schedule a call",
			id: "meeting",
			description: "Action",
			icon: "calendarSchedule",
			color: "text-emerald-500",
			bgColor: "bg-emerald-100",
			bgDark: "dark:bg-emerald-900",
			action: () => calRef.current.click(),
		},
		{
			label: "Email me",
			id: "email",
			description: "Link",
			icon: "email",
			link: {
				external: "mailto:howdy@crossrambles.com",
			},
		},
		{
			label: "Pages",
			id: "pages-divider",
			hoverable: false,
		},
		{
			label: "Stats",
			id: "stats",
			description: "Page",
			icon: "trend",
			link: {
				internal: "/stats",
			},
		},
		{
			label: "Blog",
			id: "blog",
			description: "Page",
			icon: "blog",
			link: {
				internal: "/blog",
			},
		},
		{
			label: "Bookshelf",
			id: "bookshelf",
			description: "Page",
			icon: "library",
			link: {
				internal: "/bookshelf",
			},
		},
		{
			label: "TV Series",
			id: "tvseries",
			description: "Page",
			icon: "tvClassic",
			link: {
				internal: "/watching/series",
			},
		},
		{
			label: "Bookmarks",
			id: "bookmarks",
			description: "Page",
			icon: "bookmarksMulti",
			link: {
				internal: "/bookmarks",
			},
		},
		{
			label: "Things",
			id: "things",
			description: "Page",
			icon: "stack",
			link: {
				internal: "/things",
			},
		},
		{
			label: "Friends",
			id: "links",
			description: "Page",
			icon: "people",
			link: {
				internal: "/friends",
			},
		},
		{
			label: "About Me",
			id: "about",
			description: "Page",
			icon: "me",
			link: {
				internal: "/about",
			},
		},
		// {
		// 	label: "Links",
		// 	id: "links-divider",
		// 	hoverable: false,
		// },
		// {
		// 	label: "Analytics",
		// 	id: "analytics",
		// 	description: "Link",
		// 	icon: "growth",
		// 	link: {
		// 		external: "https://analytics.ouorz.com/share/E4O9QpCn/ouorz-next",
		// 	},
		// },
		// {
		// 	label: "Thoughts",
		// 	id: "thoughts",
		// 	description: "Link",
		// 	icon: "lightBulb",
		// 	link: {
		// 		external: "https://notion.ouorz.com",
		// 	},
		// },
		// {
		// 	label: "Podcast",
		// 	id: "podcast",
		// 	description: "Link",
		// 	icon: "mic",
		// 	link: {
		// 		external: "https://kukfm.com",
		// 	},
		// },
		// {
		// 	label: "Snapod",
		// 	id: "snapod",
		// 	description: "Link",
		// 	icon: "microphone",
		// 	link: {
		// 		external: "https://www.snapodcast.com",
		// 	},
		// },
		{
			label: "Social",
			id: "social-divider",
			hoverable: false,
		},
		{
			label: "Bluesky",
			id: "bluesky",
			description: "Link",
			icon: "bluesky",
			link: {
				external: "https://bsky.app/profile/crossrambles.com",
			},
		},
		{
			label: "Instagram",
			id: "instagram",
			description: "Link",
			icon: "instagram",
			link: {
				external: "https://instagram.com/crossrambles",
			},
		},
		{
			label: "YouTube",
			id: "youtube",
			description: "Link",
			icon: "youtube",
			link: {
				external: "https://youtube.com/@crossrambles",
			},
		},
	]

	const { coverImage } = useSelector(selectGeneral)
	const [ hasCoverImage, setHasCoverImage ] = useState(false)

	useEffect(() => {
		if ( coverImage.length > 0 ) {
			setHasCoverImage(true)
		} else {
			setHasCoverImage(false)
		}
	}, [headerRef, coverImage]);

	const scrollHandler = (position: number) => {
		if (!headerRef?.current) return

		headerRef.current.style.transform = `translateY(${15 - position || 0}%)`
	}

	return (
		<ScrollWrapper handler={scrollHandler} startPosition={0} endPosition={15}>
			<header
				ref={headerRef}
				id="header"
				className={`header fixed top-0 z-50 grid h-auto w-full grid-cols-8 border-b border-gray-200 px-1 py-2 leading-14 duration-300 dark:border-b-transparent dark:backdrop-blur-lg lg:border-0 lg:bg-transparent lg:px-5 lg:py-4 ${hasCoverImage ? "max-lg:!bg-white max-lg:dark:!bg-gray-800 max-lg:!translate-y-0" : ""}`}>
				<button
					ref={calRef}
					data-cal-link="cross/meeting"
					data-cal-config={`{"theme":"${currentTheme === "dark" ? "dark" : "light"}"}`}
					style={{
						position: "absolute",
						top: "-1000vh",
						left: "-1000vw",
					}}
				/>
				<div className="col-start-1 col-end-3 flex items-center lg:items-baseline lg:space-x-2">
					<Tabs items={leftTabItems}/>
				</div>
				<OffsetTransition disabled={!nonHomePage} componentRef={titleRef}>
					<div
						ref={titleRef}
						className="col-start-3 col-end-7 flex items-center justify-center">
						{nonHomePage ? (
							<HeaderTitleComponent/>
						) : (
							<HeaderSearchBarComponent
								activateKbar={() => dispatch(activateKbar(kbarItems))}
							/>
						)}
					</div>
				</OffsetTransition>
				<div className="col-start-7 col-end-9 flex justify-end space-x-2">
					<Tabs items={rightTabItems}/>
				</div>
				<Kbar list={kbarItems}/>
			</header>
		</ScrollWrapper>
	)
}

const Header = () => {
	const headerRef = useRef<HTMLDivElement>(null)

	return (
		<HeaderTransition componentRef={headerRef}>
			<HeaderComponent headerRef={headerRef}/>
		</HeaderTransition>
	)
}

export default Header

import React from "react"
import iconsNormal from "./normal"
import { IconNames } from "../utils/propTypes"

export interface IconProps {
	/**
	 * Specify the name of the icon
	 */
	name: IconNames
}

export const Icon = ({ name = "empty" }: IconProps) => {
	return iconsNormal[name]
}

export default Icon
